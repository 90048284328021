<template>
  <div class="user-data">
    <div class="title">用户资料</div>
    <router-view></router-view>
    
    <!-- <ImgCutter 
    @cutDown="cutDown"
    label=""
    ></ImgCutter> -->
  </div>
</template>

<script>
export default {
  name: "user-data",
  
  data() {
    return {
      
    };
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.user-data {
  border: 1px solid #dddddd;
  height: 492px;
  .title {
    border-bottom: 1px solid #dddddd;

    font-size: 17px;
    font-weight: bold;
    line-height: 37px;
    color: #333333;
    padding-left: 24px;
    background: #f3f3f3;
  }
  
}
</style>